import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import OtpInput from "react-otp-input";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "../src/contexts/MyContext";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { url } from "./config";

const Otp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const { uid } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    getUser(uid);
  }, []);
  const getUser = async (uid) => {
    console.log(uid);
    try {
      const res = await axios.post(
        url + "/fetchuser",
        { userId: uid },
        { withCredentials: true }
      );
      setFirstName(res.data.firstName);
      setLastName(res.data.lastName);
      setNumber(res.data.number);
      setEmail(res.data.email);
      setGender(res.data.gender);
      setDob(res.data.dob);
    } catch (err) {
      console.log(err);
    }
  };
  async function updateUser() {
    try {
      const res = await axios.post(
        url + "/updateuser",
        {
          userId: uid,
          firstName,
          lastName,
          number,
          email,
          dob,
          gender,
          verified: 1,
        },
        { withCredentials: true }
      );
      navigate("/dashboard/personal", { replace: true });
      toast.success("OTP Verified", {
        position: "top-center",
      });
    } catch (err) {
      console.log(err);
    }
  }

  const verify = async () => {
    const otp = document.getElementById("otp").value
      ? document.getElementById("otp").value
      : "";
    try {
      const res = await axios.post(
        url + "/verifyotp",
        { phoneNumber: "+91" + number, otp: otp },
        { withCredentials: true }
      );
      if (res.data === "approved") {
        updateUser();
      } else {
        toast.error("Wrong OTP! Please Retry.", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const resend = async () => {
    try {
      const res = await axios.post(
        url + "/sendotp",
        { phoneNumber: "+91" + number },
        { withCredentials: true }
      );
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container className="bg-black pb-5">
      <Container
        className="border mt-5 p-5 justify-content-center"
        style={{ height: "100%" }}
      >
        <Row className="justify-content-center">
          <Col className="h3 mt-1 text-center text-danger col-12">
            OTP Verification
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-3 text-light col-lg-4 col-10">
            <Form.Group className="mb-3">
              <Form.Label>OTP</Form.Label>
              <Form.Control
                type="password"
                id="otp"
                placeholder="xxxxxxxx"
                aria-describedby="passwordHelpBlock"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-5 text-center text-light col-1">
            <Button variant="danger" onClick={verify} size="xs">
              <span className="h5">Verify</span>
            </Button>
          </Col>
          <Col className="h5 mt-5 text-center text-light col-1">
            <Button variant="danger" onClick={resend} size="xs">
              <span className="h5">Resend</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Otp;
