import { useContext, createContext, useState } from "react";

const MyContext = createContext();

export function useAuth() {
    return useContext(MyContext);
  }

export const MyProvider = ({children}) => {
    const [authContext, setAuthContext] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [deliveryAddress, setDeliveryAddress] = useState(null);

    return (
        <MyContext.Provider value={{ authContext, setAuthContext, user, setUser, uid, setUid, cart, setCart, total, setTotal, deliveryAddress, setDeliveryAddress }}>
            {children}
        </MyContext.Provider>
    )
}

export default MyContext;