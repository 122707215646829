import React from "react";
import Carousel from "react-bootstrap/Carousel";

const Slider = () => {
  return (
    <>
      <Carousel className="mt-1 mb-5">
        <Carousel.Item className="carousel-img bg-img-1" interval={2000}>
          <Carousel.Caption>
            <h3>Welcome to Fusion Gaming</h3>
            <p>PC Building Simplified</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel-img bg-img-2" interval={2000}>
          <Carousel.Caption>
            <h3>You Dream, We Build!</h3>
            <p>PC tailored to perfection by professionals</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel-img bg-img-3" interval={2000}>
          <Carousel.Caption>
            <h3>Data driven PC building</h3>
            <p>
              All our machines are built to unique solutions at affordable costs
              with the help of Price-to-Performnace ratio charts
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Slider;
