import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import Header from "../Header";
import { useAuth } from "../../contexts/MyContext";
import Footer from "../Footer";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { url } from "../../config";

const DashboardOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { uid, user, setAuthContext, setUser, setUid } = useAuth();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function getOrders() {
      try {
        const res = await axios.post(
          url + "/getOrders",
          { uid },
          { withCredentials: true }
        );
        setOrders(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    getOrders();
  }, []);

  const handleSubmit = async (e) => {
    setAuthContext(null);
    setUser(null);
    setUid(null);
    try {
      const res = await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/", { replace: true });
  };
  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row style={{ minHeight: "55vh" }}>
          <Col className="col-xxl-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 m-3 mt-5">
            <Row className="p-4 text-light border-danger border rounded ">
              Hello {user} !
            </Row>
            <Row className="p-4 text-light mt-5 border-danger border rounded">
              <Row className="justify-content-center mt-5">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/personal"
                  >
                    Profile Info
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/addresses"
                  >
                    Saved Addresses
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-danger col-12">
                  <Link
                    className="link-danger link-underline-opacity-0"
                    to="/dashboard/orders"
                  >
                    Your Orders
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-5 px-4 text-light col-12">
                  <Button variant="danger" onClick={handleSubmit} size="sm">
                    <span className="h5">LOG OUT</span>
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col className="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12 m-3 border border-light rounded">
            <Row className="h3 m-3 mt-3 text-danger">Order Information</Row>
            <Row className="h5 mt-5 text-light">
              {orders[0] ? (
                <Table striped bordered hover responsive variant="dark">
                  <thead>
                    <tr>
                      <th className="h5 text-danger text-center">S.No.</th>
                      <th className="h5 text-danger text-center">Item</th>
                      <th className="h5 text-danger text-center">QTY</th>
                      <th className="h5 text-danger text-center">Total</th>
                      <th className="h5 text-danger text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, i) => (
                      <tr>
                        <td className="small text-center">{i + 1}</td>
                        <td style={{ minWidth: "500px", whiteSpace: "normal" }}>
                          <small className="text-light">
                            {title(JSON.parse(order.product).price)}
                          </small>
                          <br></br>
                          <small className="text-secondary">
                            {JSON.parse(order.product).cpu}/
                            {JSON.parse(order.product).gpu}/
                            {JSON.parse(order.product).ram}/
                            {JSON.parse(order.product).cabinet}
                          </small>
                        </td>
                        <td>{order.qty}</td>
                        <td>{order.total}</td>
                        <td>{order.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                "No Orders Found!"
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardOrders;
