import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useAuth } from "../../contexts/MyContext";
import { url } from "../../config";

const DashboardPersonalInformation = () => {
  const navigate = useNavigate();
  const { uid, user, setAuthContext, setUser, setUid } = useAuth();

  const handleSubmit = async (e) => {
    setAuthContext(null);
    setUser(null);
    setUid(null);
    try {
      const res = await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/", { replace: true });
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    const getUser = async (uid) => {
      try {
        const res = await axios.post(
          url + "/fetchuser",
          { userId: uid },
          { withCredentials: true }
        );
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setNumber(res.data.number);
        setEmail(res.data.email);
        setGender(res.data.gender);
        setDob(res.data.dob);
        setVerified(res.data.verified);
      } catch (err) {
        console.log(err);
      }
    };
    getUser(uid);
  }, []);

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row style={{ minHeight: "55vh" }}>
          <Col className="col-xxl-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 m-3 mt-5">
            <Row className="p-4 text-light border-danger border rounded ">
              Hello {user} !
            </Row>
            <Row className="p-4 text-light mt-5 border-danger border rounded">
              <Row className="justify-content-center mt-5">
                <Col className="h5 px-1 text-danger col-12">
                  <Link
                    className="link-danger link-underline-opacity-0"
                    to="/dashboard/personal"
                  >
                    Profile Info
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/addresses"
                  >
                    Saved Addresses
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/orders"
                  >
                    Your Orders
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-5 px-4 text-light col-12">
                  <Button variant="danger" onClick={handleSubmit} size="sm">
                    <span className="h5">LOG OUT</span>
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col className="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 m-3 border border-light rounded">
            <Row className="h3 mt-3 text-danger">
              <Col className="m-3">
                Person Information (
                <Link
                  className="link-secondary h5 link-underline-opacity-0"
                  to="/dashboard/personal/update"
                >
                  Update
                </Link>
                )
              </Col>
            </Row>
            <Row className="h5 mt-5 px-3 text-light">
              <Col className="col-6">Firstname</Col>
              <Col className="col-6">Lastname</Col>
              <Col className="col-6 text-danger">{firstName}</Col>
              <Col className="col-6 text-danger">{lastName}</Col>
            </Row>
            <Row className="h5 mt-4 px-3 text-light">
              <Col className="col-6">
                Date Of Birth{" "}
                <span className="small text-secondary">(YYYY-MM-DD)</span>
              </Col>
              <Col className="col-6">Gender</Col>
              <Col className="col-6 text-danger">{dob}</Col>
              <Col className="col-6 text-danger">{gender}</Col>
            </Row>
            <Row className="h5 mt-4 mb-4 px-3 text-light">
              <Col className="col-6">Contact Number</Col>
              <Col className="col-6">Email ID</Col>
              <Col className="col-6 text-danger">
                {number}{" "}
                {verified ? (
                  <span className="small text-secondary">Verified</span>
                ) : (
                  <Link
                    className="link-secondary h5 link-underline-opacity-0"
                    to="/dashboard/personal/otp"
                  >
                    (Verify)
                  </Link>
                )}
              </Col>
              <Col className="col-6 text-danger">{email}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardPersonalInformation;
