import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useAuth } from "../../contexts/MyContext";
import { url } from "../../config";

const Cart = () => {
  const [firstName, setFirstName] = useState("");
  const { cart, setCart, total, setTotal } = useAuth();
  const { uid } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async (uid) => {
      try {
        const res = await axios.post(
          url + "/fetchuser",
          { userId: uid },
          { withCredentials: true }
        );
        setFirstName(res.data.firstName);
      } catch (err) {
        console.log(err);
      }
    };
    const getCart = async (uid) => {
      try {
        const res = await axios.post(
          url + "/getCart",
          { userid: uid },
          { withCredentials: true }
        );
        setCart(res.data.result);
        setTotal(res.data.tot);
      } catch (err) {
        console.log(err);
      }
    };
    getCart(uid);
    getUser(uid);
  }, []);

  async function updateQty(itemid, qty) {
    try {
      const upd = await axios.post(
        url + "/updateQty",
        { itemid, qty, userid: uid },
        { withCredentials: true }
      );
      setCart(upd.data.result);
      setTotal(upd.data.tot);
    } catch (err) {
      console.log(err);
    }
  }

  async function checkout() {
    try {
      navigate("/checkout", { replace: true });
      const upd = await axios.post(
        url + "/checkout",
        { uid },
        { withCredentials: true }
      );
      navigate("/checkout", { replace: true });
    } catch (err) {
      console.log(err);
    }
  }

  async function delItem(itemid) {
    try {
      const upd = await axios.post(
        url + "/delItem",
        { itemid, userid: uid },
        { withCredentials: true }
      );
      setCart(upd.data.result);
      setTotal(upd.data.tot);
    } catch (err) {
      console.log(err);
    }
  }

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row style={{ minHeight: "55vh" }}>
          <Col className="col-xxl-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 m-3 mt-5">
            <Row className="p-4 text-light border-danger border rounded ">
              Hello {firstName} !
            </Row>
          </Col>
          <Col className="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 m-3 border border-light rounded">
            <Row className="h3 m-3 mt-3 text-danger">
              <Col className="lg-9">Your Items</Col>
            </Row>
            <Row className="h5 mt-5 px-3 text-light">
              {cart.length !== 0 ? (
                <Table responsive striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th className="h5 text-danger text-center">S.No.</th>
                      <th className="h5 text-danger text-center">Items</th>
                      <th className="h5 text-danger text-center">Quantity</th>
                      <th className="h5 text-danger text-center">Price(₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((i, index) => (
                      <tr key={index}>
                        <td className="small text-center">{index + 1}</td>
                        <td
                          className="small"
                          style={{ minWidth: "500px", whiteSpace: "normal" }}
                        >
                          <Row>
                            <Col className="col-xxl-10 col-xl-10 col-lg-10 col-md-8 col-7">
                              <span className="text-light">
                                {title(JSON.parse(i.item).price)}
                              </span>
                              <br></br>
                              <small className="text-secondary">
                                {JSON.parse(i.item).cpu}/
                                {JSON.parse(i.item).gpu}/
                                {JSON.parse(i.item).ram}/
                                {JSON.parse(i.item).cabinet}
                              </small>
                            </Col>
                            <Col
                              className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-2 "
                              style={{ height: "40px" }}
                            >
                              <Button
                                variant="outline-danger"
                                onClick={(e) => delItem(i.itemid)}
                                style={{ height: "25px" }}
                                size="sm"
                              >
                                Del
                              </Button>
                            </Col>
                            <Col
                              className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-3"
                              style={{ height: "40px" }}
                            >
                              <Row className="ms-2 me-2">
                                <Button
                                  variant="outline-danger"
                                  onClick={(e) => updateQty(i.itemid, 1)}
                                  style={{ height: "20px" }}
                                  size="sm"
                                >
                                  +
                                </Button>
                              </Row>
                              <Row className="ms-2 mt-1 me-2">
                                <Button
                                  variant="outline-danger"
                                  onClick={(e) => {
                                    i.qty > 1
                                      ? updateQty(i.itemid, -1)
                                      : updateQty(i.itemid, 0);
                                  }}
                                  style={{ height: "20px" }}
                                  size="sm"
                                >
                                  -
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                        </td>
                        <td className="small text-center">{i.qty}</td>
                        <td className="small text-center">
                          {new Intl.NumberFormat("en-IN").format(
                            JSON.parse(i.item).price * i.qty
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                "No items in cart!"
              )}
            </Row>
            {cart.length > 0 ? (
              <Row className="justify-content-end align-items-end">
                <Col className="col-lg-4 col-md-5 col-5 h4 text-danger">
                  Total:
                  {" ₹ " + new Intl.NumberFormat("en-IN").format(total) + "/-"}
                </Col>
                <Col className="col-lg-2 col-md-5 col-5">
                  <Button variant="danger" size="lg" onClick={checkout}>
                    <span className="h5">Checkout</span>
                  </Button>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Cart;
