import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link, replace } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { url } from "./config";

import { auth } from "./firebase";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword } from "firebase/auth";

import { useAuth } from "./contexts/MyContext";

const Login = () => {
  const { uid, setUser, setAuthContext, setUid, setCart } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [firstName, setFirstName] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setUid(auth.currentUser.uid);
      createRefreshToken(auth.currentUser.refreshToken, auth.currentUser.uid);
      if (auth.currentUser.emailVerified) {
        setAuthContext(auth.currentUser.accessToken);
        fetchUserData(auth.currentUser.uid);
        console.log(auth.currentUser.refreshToken);
        toast.success("User logged in Successfully", {
          position: "top-center",
        });
        navigate("/", { replace: true });
      } else {
        navigate("/unauthorized", { replace: true });
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  const createRefreshToken = async (refreshToken, uid) => {
    try {
      const res = await axios.post(
        url + "/createCookie",
        { refreshToken, uid },
        { withCredentials: true }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserData = async (uid) => {
    try {
      const res = await axios.post(
        url + "/fetchuser",
        { userId: uid },
        { withCredentials: true }
      );
      setUser(res.data.firstName);
      setUid(res.data.userId);
      getCart(uid);
    } catch (err) {
      console.log("User is not logged in");
    }
  };
  const getCart = async (uid) => {
    try {
      const res = await axios.post(
        url + "/getCart",
        { userid: uid },
        { withCredentials: true }
      );
      setCart(res.data.result);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-black">
      <Container className="bg-black pb-5">
        <Container
          className="border mt-5 p-5 justify-content-center"
          style={{ height: "100%" }}
        >
          <Row className="justify-content-center mt-5">
            <Col className="h1 text-center text-light col-12">SIGN IN</Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light col-10">
              <small>NEW TO FUSION GAMING?</small>
              <a
                className="link-danger px-1 link-underline-opacity-0"
                href="/register"
              >
                SIGN UP
              </a>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-5 text-light col-lg-4 col-10">
              <Form.Group className="mb-3" id="numberGroup">
                <Form.Label htmlFor="number">Email</Form.Label>
                <Form.Control
                  type="text"
                  id="number"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="xxxxxxxxxx"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-1 text-light col-lg-4 col-10">
              <Form.Group className="mb-3" id="passwordGroup">
                <Form.Label htmlFor="password">Password</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="xxxxxxxx"
                  aria-describedby="passwordHelpBlock"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-5 text-center text-light col-12">
              <Button variant="danger" onClick={handleSubmit} size="lg">
                <span className="h5">LOGIN</span>
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-dark col-12">
              <small>
                {firstName
                  ? "Login is successful"
                  : errMsg
                  ? errMsg
                  : "Click to Login"}
              </small>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col className="h5 mt-1 text-center text-light col-12">
              <Link
                className="link-danger link-underline-opacity-0"
                to="/forgotpassword"
              >
                Forgot Password
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Login;
