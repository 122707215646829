import React, { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  async function handleSubmit() {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Reset Email Sent. Kindly Check!", {
        position: "top-center",
      });
      navigate("/login", { replace: true });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="bg-black">
      <Container className="bg-black pb-5">
        <Container
          className="border mt-5 p-5 justify-content-center"
          style={{ height: "100%" }}
        >
          <Row className="justify-content-center mt-5">
            <Col className="h1 text-center text-light col-12">
              Reset Password
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-5 text-light col-lg-4 col-10">
              <Form.Group className="mb-3" id="numberGroup">
                <Form.Label htmlFor="number">Email</Form.Label>
                <Form.Control
                  type="text"
                  id="number"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="xxxxxxxxxx"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-5 text-center text-light col-12">
              <Button variant="danger" onClick={handleSubmit} size="lg">
                <span className="h5">Reset Password</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ForgotPassword;
