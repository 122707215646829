import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import axios from "axios";

import RequireAuth from "./components/RequireAuth";

import Configurator from "./Configurator";
import Unauthoriszed from "./components/Unauthorized";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Login from "./Login2";
import CusDetails from "./CusDetails";
import Register from "./Register";
import Otp from "./Otp";
import DashboardPersonalInformation from "./components/dashboard/DashboardPersonalInformation";
import DashboardOrders from "./components/dashboard/DashboardOrders";
import DashboardSavedAddresses from "./components/dashboard/DashboardSavedAddresses";
import UpdatePersonal from "./components/dashboard/UpdatePersonal";
import UpdateAddresses from "./components/dashboard/UpdateAddresses";
import Cart from "./components/cart/Cart";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Whatsapp from "./components/Whatsapp";
import Container from "react-bootstrap/esm/Container";
import { Details } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MyProvider, useAuth } from "./contexts/MyContext";
import ForgotPassword from "./ForgotPassword";
import Checkout from "./components/Checkout";
import PayVerification from "./PayVerification";
import PaySuccess from "./PaySuccess";
import PayFail from "./PayFail";
import { url } from "./config";

axios.defaults.withCredentials = true;

const App = () => {
  const { setUser, setAuthContext, setUid, setCart } = useAuth();

  useEffect(() => {
    async function reload() {
      try {
        const res = await axios.post(url + "/authenticate", {
          withCredentials: true,
        });
        if (res.data.status != 400) {
          setAuthContext(res.data.accessToken);
          fetchUserData(res.data.user_id);
        }
      } catch (err) {
        console.log(err);
      }
    }
    reload();
  }, []);
  const fetchUserData = async (uid) => {
    try {
      const res = await axios.post(
        url + "/fetchuser",
        { userId: uid },
        { withCredentials: true }
      );
      setUser(res.data.firstName);
      setUid(res.data.userId);
      getCart(uid);
    } catch (err) {
      console.log("User is not logged in");
    }
  };
  const getCart = async (uid) => {
    try {
      const res = await axios.post(
        url + "/getCart",
        { userid: uid },
        { withCredentials: true }
      );
      setCart(res.data.result);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-black">
      <Container className="bg-black pb-5">
        <Whatsapp />
        <Header />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/config" element={<Configurator />} />
          <Route path="/login" element={<Login />} />
          <Route path="/CusDetails" element={<CusDetails />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/payment/validate/:merchantTransactionId"
            element={<PayVerification />}
          />
          <Route
            path="/payment/success/:merchantTransactionId"
            element={<PaySuccess />}
          />
          <Route
            path="/payment/fail/:merchantTransactionId"
            element={<PayFail />}
          />
          <Route path="*" element={<Home />} />
          <Route path="/unauthorized" element={<Unauthoriszed />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route element={<RequireAuth />}>
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />

            <Route path="/dashboard/personal/otp" element={<Otp />} />
            <Route
              path="/dashboard/personal"
              element={<DashboardPersonalInformation />}
            />
            <Route
              path="/dashboard/personal/update"
              element={<UpdatePersonal />}
            />
            <Route
              path="/dashboard/addresses"
              element={<DashboardSavedAddresses />}
            />
            <Route
              path="/dashboard/addresses/update"
              element={<UpdateAddresses />}
            />
            <Route path="/dashboard/orders" element={<DashboardOrders />} />
          </Route>
        </Routes>
        <Footer />
      </Container>
    </div>
  );
};

export default App;
