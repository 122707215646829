import React, { useState, useEffect } from "react";
import axios from "axios";

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { useAuth } from "../../contexts/MyContext";
import Button from "react-bootstrap/esm/Button";

import toast from "react-hot-toast";
import { url } from "../../config";

const UpdateAddresses = () => {
  const { uid } = useAuth();
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [userId, setUserId] = useState("");

  const [status, setStatus] = useState("");

  useEffect(() => {
    const getAddress = async () => {
      try {
        const res = await axios.post(
          url + "/fetchaddresses",
          { id: uid },
          { withCredentials: true }
        );
        setAddresses(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAddress();
  }, []);

  async function addAddress() {
    try {
      const add = await axios.post(
        url + "/addaddress",
        { userid: uid, address },
        { withCredentials: true }
      );
      const res = await axios.post(
        url + "/fetchaddresses",
        { id: uid },
        { withCredentials: true }
      );
      setAddresses(res.data);
      setAddress("Enter New Address");
      toast.success("Address added Successfully", {
        position: "top-center",
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function delAddress(id) {
    try {
      const del = await axios.post(
        url + "/deladdress",
        { addressid: id },
        { withCredentials: true }
      );
      const res = await axios.post(
        url + "/fetchaddresses",
        { id: uid },
        { withCredentials: true }
      );
      setAddresses(res.data);
      setAddress("Enter New Address");
      toast.success("Address added Successfully", {
        position: "top-center",
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row className="justify-content-center mt-5">
          <Col className="h1 text-center text-light col-12">
            Update Addresses
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-secondary col-12">
            <small>Secure Data Entry</small>
          </Col>
        </Row>
        <Col>
          {addresses[0] ? (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr key={1}>
                  <th className="h5 text-danger text-center">S.No.</th>
                  <th className="h5 text-danger text-center">Address</th>
                  <th className="h5 text-danger text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {addresses.map((a, i) => (
                  <tr key={i + 2}>
                    <td className="small text-center">{i + 1}</td>
                    <td>{a.address}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={(e) => delAddress(a.addressid)}
                        size="sm"
                      >
                        <span className="h5">Delete</span>
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr key={addresses.length + 2}>
                  <td className="small text-center">{addresses.length + 1}</td>
                  <td>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        id={addresses.length + 1}
                        placeholder="Enter New Address"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Button variant="danger" onClick={addAddress} size="sm">
                      <span className="h5">ADD</span>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr key={1}>
                  <th className="h5 text-danger text-center">S.No.</th>
                  <th className="h5 text-danger text-center">Address</th>
                  <th className="h5 text-danger text-center">Actions</th>
                </tr>
                <tr>
                  <td className="small text-center">{addresses.length + 1}</td>
                  <td>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        id={addresses.length + 1}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter New Address"
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Button variant="danger" onClick={addAddress} size="sm">
                      <span className="h5">ADD</span>
                    </Button>
                  </td>
                </tr>
              </thead>
            </Table>
          )}
        </Col>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-dark col-12">
            <small>{status}</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdateAddresses;
