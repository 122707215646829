import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCk_MMV5k-7Vs7E8esjMh4Nv3XIZEiVN3I",
  authDomain: "fusiongaming-71131.firebaseapp.com",
  projectId: "fusiongaming-71131",
  storageBucket: "fusiongaming-71131.appspot.com",
  messagingSenderId: "389674781978",
  appId: "1:389674781978:web:a1c789d3b25b0059ce70a3",
  measurementId: "G-8QZC7FSF60"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();
