import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import axios from "../api/axios";
import { useAuth } from "../contexts/MyContext";
import Button from "react-bootstrap/esm/Button";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../config";

const Checkout = () => {
  const { uid, cart, total, deliveryAddress, setDeliveryAddress } = useAuth();

  const [addresses, setAddresses] = useState([]);
  useEffect(() => {
    const getAddress = async (uid) => {
      try {
        const res = await axios.post(
          url + "/fetchaddresses",
          { id: uid },
          { withCredentials: true }
        );
        setAddresses(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAddress(uid);
  }, []);

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  const handleChange = (event) => {
    setDeliveryAddress(event.target.value);
    console.log(cart, total, deliveryAddress, uid);
  };

  const pay = async () => {
    try {
      const res = await axios.post(
        url + "/pay",
        {
          userId: uid,
          amount: total,
          cart,
          deliveryAddress,
        },
        { withCredentials: true }
      );
      console.log(res.data);
      if (res.data.success) {
        console.log(res.data.data.instrumentResponse.redirectInfo.url);
        window.location.href =
          res.data.data.instrumentResponse.redirectInfo.url;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Container className="bg-black">
        <Row style={{ minHeight: "55vh" }}>
          <Col className="col-lg-12 mt-5 border border-light rounded">
            <Row className="h3 m-3 mt-3 text-danger">
              <Col className="lg-9">
                Choose Delivery Address (
                <Link
                  className="link-secondary h5 link-underline-opacity-0"
                  to="/dashboard/addresses/update"
                >
                  Add/Update Addresses
                </Link>
                )
              </Col>
            </Row>
            <Row className="h5 mt-3 px-3 text-light">
              <Col>
                {addresses[0] ? (
                  <Table striped bordered hover responsive variant="dark">
                    <thead>
                      <tr>
                        <th className="h5 text-danger text-center">S.No.</th>
                        <th className="h5 text-danger text-center">Address</th>
                        <th className="h5 text-danger text-center">Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addresses.map((address, i) => (
                        <tr>
                          <td className="small text-center">{i + 1}</td>
                          <td
                            className="small"
                            style={{ minWidth: "500px", whiteSpace: "normal" }}
                          >
                            {address.address}
                          </td>
                          <td className="small text-center">
                            <Form.Check
                              type="radio"
                              id={address.id}
                              label={address.id}
                              name="addresses"
                              value={address.address}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  "No Addresses Saved! Kindly add a new address!"
                )}
              </Col>
            </Row>
            <Row className="h3 m-3 mt-5 text-danger">
              <Col className="lg-9">Order Details</Col>
            </Row>
            <Row className="h5 mt-3 px-3 text-light">
              <Table striped bordered hover responsive variant="dark">
                <thead>
                  <tr>
                    <th className="h5 text-danger text-center">S.No.</th>
                    <th className="h5 text-danger text-center">Items</th>
                    <th className="h5 text-danger text-center">Quantity</th>
                    <th className="h5 text-danger text-center">Price(₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((i, index) => (
                    <tr key={index}>
                      <td className="small text-center">{index + 1}</td>
                      <td
                        className="small"
                        style={{ minWidth: "500px", whiteSpace: "normal" }}
                      >
                        <span className="text-light">
                          {title(JSON.parse(i.item).price)}
                        </span>
                        <br></br>
                        <small className="text-secondary">
                          {JSON.parse(i.item).cpu}/{JSON.parse(i.item).gpu}/
                          {JSON.parse(i.item).ram}/{JSON.parse(i.item).cabinet}
                        </small>
                      </td>
                      <td className="small text-center">{i.qty}</td>
                      <td className="small text-center">
                        {new Intl.NumberFormat("en-IN").format(
                          JSON.parse(i.item).price * i.qty
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </Col>
          <Row className="justify-content-end align-items-end">
            <Col className="col-lg-4 h4 text-danger">
              Total:
              {" ₹ " + new Intl.NumberFormat("en-IN").format(total) + "/-"}
            </Col>
            <Col className="col-lg-2">
              <Button variant="danger" size="lg" onClick={pay}>
                <span className="h5">Proceed to Pay</span>
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default Checkout;
