import React, { useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

const PayFail = () => {
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => navigate("/cart"), 3000);
  }, []);
  return (
    <div className="bg-black">
      <Container className="bg-black pb-5">
        <Container
          className="border mt-5 p-5 justify-content-center"
          style={{ height: "55vh" }}
        >
          <Row className="justify-content-center mt-5">
            <Col className="h1 text-center text-danger col-12">
              Payment Failed!
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light mt-5 col-10">
              <small>Please try again.</small>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light col-10">
              <small>Redirecting to cart...</small>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default PayFail;
