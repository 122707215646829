import axios from "axios"; // Make sure axios is correctly imported
import { url } from "../config";

const BASE_URL = url;

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
