import React, { useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../src/contexts/MyContext";
import axios from "axios";
import { url } from "./config";

const PaySuccess = () => {
  let navigate = useNavigate();
  const { uid, setCart, total, deliveryAddress } = useAuth();

  useEffect(() => {
    createOrder();
    getCart();
    setTimeout(() => navigate("/dashboard/orders"), 3000);
  }, []);

  async function createOrder() {
    try {
      console.log(uid);
      const res1 = await axios.post(url + "/createOrder", {
        withCredentials: true,
      });
      console.log(res1.data);
    } catch (err) {
      console.log(err);
    }
  }

  const getCart = async (uid) => {
    try {
      const res = await axios.post(
        url + "/getCart",
        { userid: uid },
        { withCredentials: true }
      );
      setCart(res.data.result);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-black">
      <Container className="bg-black pb-5">
        <Container
          className="border mt-5 p-5 justify-content-center"
          style={{ height: "55vh" }}
        >
          <Row className="justify-content-center mt-5">
            <Col className="h1 text-center text-danger col-12">
              Payment Success!
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light mt-5 col-10">
              <small>Order Confirmed.</small>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light col-10">
              <small>Redirecting to your orders...</small>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default PaySuccess;
